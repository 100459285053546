import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class PrivacyAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <DocumentTitle title="Política de Privacidad" />
        <h2>Política de Privacidad</h2>

        <p></p>
        <p>
          Y Credito considera fundamental la protección de su privacidad. La
          presente Política de Privacidad establece los términos y condiciones
          que rigen el tratamiento de la información y datos personales de los
          usuarios que acceden a nuestra aplicación y/o página web (en adelante,
          "Plataforma").
        </p>
        <p></p>
        <p>
          Esta Política de Privacidad forma parte integral de nuestros Términos
          y Condiciones. Al acceder a nuestra Plataforma o utilizar cualquiera
          de nuestros servicios, usted acepta expresamente las prácticas
          descritas en esta Política. Si no está de acuerdo con estos términos,
          le recomendamos abstenerse de utilizar nuestros servicios.
        </p>
        <p></p>
        <p>Alcance y Consentimiento</p>
        <p></p>
        <p>
          Al utilizar la aplicación móvil o el sitio web de Y Credito
          (colectivamente, la "Plataforma"), usted otorga su consentimiento
          expreso para el tratamiento de su información personal según lo
          establecido en esta Política. La información recopilada será utilizada
          exclusivamente para los fines especificados y bajo estrictas medidas
          de seguridad.
        </p>
        <p></p>
        <p>Permisos y Autorizaciones Requeridas</p>
        <p></p>
        <p>1. Información del Dispositivo</p>
        <p>
          Para garantizar la seguridad de nuestros servicios, Y Credito recopila
          información específica de su dispositivo, incluyendo especificaciones
          técnicas e identificadores únicos. Esta información se transmite de
          forma segura a https://home.y-credit.net/ y se utiliza para la
          prevención de fraude y la optimización de nuestros servicios
          financieros. El procesamiento de estos datos se realiza mediante
          protocolos de seguridad avanzados y está sujeto a su autorización
          expresa.
        </p>
        <p></p>
        <p>2. Almacenamiento</p>
        <p>
          Para facilitar el proceso de verificación de identidad (KYC) y
          optimizar su experiencia de usuario, Y Credito requiere acceso al
          almacenamiento de su dispositivo. Esta autorización permite el manejo
          seguro y eficiente de su documentación, garantizando un proceso de
          evaluación ágil y confiable.
        </p>
        <p></p>
        <p>3. Verificación Biométrica</p>
        <p>
          Para proteger su identidad y prevenir el fraude, Y Credito requiere el
          acceso a la cámara de su dispositivo para realizar una verificación
          biométrica mediante selfie y prueba de vida. Este proceso es esencial
          para garantizar la seguridad de su préstamo y proteger sus datos
          personales.
        </p>
        <p></p>
        <p>4. Geolocalización</p>
        <p>
          Para garantizar la seguridad de su cuenta y verificar su elegibilidad,
          Y Credito recopila y transmite de forma segura (https) los datos de
          ubicación de su dispositivo a https://home.y-credit.net/. Este
          servicio está disponible exclusivamente en Perú y nos comprometemos a
          no compartir sus datos con terceros sin su consentimiento explícito.
        </p>
        <p></p>
        <p>5. Contactos de Emergencia</p>
        <p>
          Para garantizar una comunicación efectiva, Y Credito requiere que
          seleccione manualmente sus contactos de emergencia. Esta información
          se transmitirá de forma encriptada, se conservará por 12 meses y será
          utilizada únicamente cuando no podamos contactarlo. Para solicitar la
          eliminación de sus datos, puede escribir a hola@y-credit.net (proceso
          de 3-5 días hábiles).
        </p>
        <p></p>
        <p>Seguridad y Protección de Datos</p>
        <p></p>
        <p>
          Y Credito implementa rigurosas medidas de seguridad técnicas,
          administrativas y físicas para proteger su información personal. Todas
          las transmisiones de datos se realizan mediante cifrado SSL de 128
          bits y conexiones seguras HTTPS.
        </p>
        <p></p>
        <p>Medidas de Seguridad Implementadas:</p>
        <p></p>
        <p>1. Encriptación avanzada para la transmisión de datos</p>
        <p>2. Verificación de identidad mediante múltiples factores</p>
        <p>3. Monitoreo continuo de sistemas de seguridad</p>
        <p>4. Acceso restringido a la información personal</p>
        <p>5. Protocolos de seguridad actualizados regularmente</p>
        <p></p>
        <p>Conservación y Eliminación de Datos</p>
        <p></p>
        <p>
          Y Credito conservará su información personal durante el tiempo
          necesario para cumplir con los fines establecidos en esta política y
          nuestras obligaciones legales. El período de retención estándar es de
          12 meses desde su última interacción con nuestros servicios.
        </p>
        <p></p>
        <p>Derechos del Usuario</p>
        <p></p>
        <p>Usted tiene derecho a:</p>
        <p>1. Acceder a su información personal</p>
        <p>2. Solicitar la rectificación de datos inexactos</p>
        <p>3. Solicitar la eliminación de sus datos</p>
        <p>4. Oponerse al tratamiento de sus datos</p>
        <p>5. Revocar su consentimiento</p>
        <p></p>
        <p>
          Para ejercer estos derechos, puede contactarnos a través de
          hola@y-credit.net.
        </p>
        <p></p>
        <p>Contacto</p>
        <p></p>
        <p>Para consultas sobre esta Política de Privacidad:</p>
        <p></p>
        <p>Y Credito</p>
        <p>Correo electrónico: hola@y-credit.net</p>
        <p>Horario de atención: Lunes a sábado de 8:30 a 17:00</p>
        <p>Sitio web: https://www.y-credit.net</p>
      </div>
    );
  }
}
export default PrivacyAgreement;
