import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";
import Header from "./Header";
import Banner from "./Banner";

import Footer from "./Footer";
import "./static/style";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class Home extends React.PureComponent {
  state = {
    isMobile,
    showShadow: false,
    isBgWhite: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    window.addEventListener("scroll", this.bindScroll);
  }

  bindScroll = (event) => {
    this.setState({
      isScroll: true,
    });
  };
  navToShadow = (e) => {
    this.setState({ showShadow: e.mode === "leave" });
  };

  render() {
    return [
      <Header
        key="header"
        isScroll={this.state.isScroll}
        isMobile={this.state.isMobile}
        className={this.state.isBgWhite ? "btn-white" : ""}
      />,
      <Banner
        key="banner"
        isMobile={this.state.isMobile}
        navToShadow={this.navToShadow}
      />,
      <Footer key="footer" isMobile={this.state.isMobile} />,
      <DocumentTitle title="Y Credito" />,
    ];
  }
}
export default Home;
