import React from "react";
import DocumentTitle from "react-document-title";
import { enquireScreen } from "enquire-js";

import "./CommonPage.less";

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});

class ServiceAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
  }

  render() {
    return (
      <div className={"contentWrapper"}>
        <DocumentTitle title="Terminos de Uso" />
        <h1>Terminos de Uso</h1>

        <p></p>
        <p>1. INTRODUCCIÓN</p>
        <p></p>
        <p>
          El presente documento establece los términos y condiciones de uso
          ("Términos y Condiciones") que serán aplicables a aquellos que accedan
          al Sitio web ("Sitio Web") y/o Aplicación ("Aplicación/App").
        </p>
        <p></p>
        <p>
          Es necesario también leer la Política de Privacidad y Protección de
          Datos Personales ("Política de Privacidad"), en donde detallamos
          nuestras prácticas de tratamiento de información
          https://www.y-credit.net/privacy-agreement. Nos preocupamos por
          mantener seguros a nuestros Usuarios y estamos en constante trabajo
          para recopilar la información de manera confidencial.
        </p>
        <p></p>
        <p>
          La utilización del Sitio Web y/o Aplicación/App implica la aceptación
          sin reservas de todas las disposiciones contenidas en los presentes
          Términos y Condiciones, en términos de la segunda fracción del
          Artículo No. 141 y 141-A del Código Civil, los cuales son tanto
          vinculantes como obligatorios. En caso alguno de los mencionados
          anteriormente no esté conforme con el presente documento, deberá
          abstenerse de acceder y utilizar el Sitio Web y/o Aplicación/App para
          hacer uso del servicio ofrecido por Y Credito.
        </p>
        <p></p>
        <p>
          En caso de incumplimiento a cualquiera de las disposiciones contenidas
          en los presentes Términos y Condiciones, Y Credito podrá suspenderles
          o negarles el acceso al Sistema, en cualquier momento y sin necesidad
          de notificación previa de ningún tipo, así como presentar las
          denuncias, querellas o reclamaciones que sean procedentes ante las
          autoridades competentes, proporcionando la información necesaria para
          la investigación y persecución del ilícito o delito del que se trate.
        </p>
        <p></p>
        <p>
          Se considerará infracción a los presentes Términos y Condiciones,
          entre otros, la comisión o participación en actos que constituyan
          conductas abusivas, inapropiadas o ilícitas, actividades fraudulentas
          o ilegales.
        </p>
        <p>2. DEFINICIONES</p>
        <p></p>
        <p>
          Sin perjuicio de los términos definidos en diversas secciones del
          presente documento, los términos aquí utilizados con la primera letra
          mayúscula y que no sean definidos de forma diversa, tendrán el
          significado que a continuación se les atribuye, pudiendo ser
          utilizados en masculino, femenino o neutro, así como en singular o en
          plural, indistintamente y según el contexto:
        </p>
        <p></p>
        <p>
          ● "Afiliados" significa, en relación con una parte, cualquier entidad
          que controla, está bajo el control o está bajo control común con esa
          parte, donde el control significa la propiedad directa o indirecta de
          más del 50 por ciento del capital con derecho a voto o derecho similar
          de propiedad de esa parte o el poder legal para dirigir o causar la
          dirección de la administración general y las políticas de esa parte,
          ya sea a través de la propiedad del capital con derecho a voto, por
          contrato o de otra manera, y los controles y controlados se
          interpretarán en consecuencia;
        </p>
        <p></p>
        <p>
          ● "Servicio" significa cualquier forma de servicio o producto
          financiero que podamos ofrecerle de conformidad con estos Términos y
          Condiciones y, de tiempo en tiempo, puede suscribirse y el "Servicio"
          se interpretará en consecuencia;
        </p>
        <p></p>
        <p>
          ● "Cuenta" significa su cuenta individual mientras accede al Sistema y
          utiliza el Servicio;
        </p>
        <p></p>
        <p>
          ● "Aplicación/App" significa la aplicación electrónica suministrada
          por Y Credito y/o sus Afiliadas para que el Usuario acceda al Sistema
          y al Servicio;
        </p>
        <p></p>
        <p>
          ● "Sitio web" significa cualquier sitio web operado por Y Credito o
          cualquiera de sus Afiliadas de tiempo en tiempo;
        </p>
        <p></p>
        <p>
          ● "El Usuario/Usuario" significa a aquella persona natural o jurídica
          que utiliza los Servicios de Y Credito por medio del Sitio Web y/o
          Aplicación/App;
        </p>
        <p></p>
        <p>
          ● "Leyes Vigentes": significan todas las leyes, códigos, normas
          jurídicas, ordenanzas, reglamentos, tratados, reglas, normas,
          permisos, resoluciones, órdenes, interpretaciones, decisiones,
          interdictos, así como las normas, resoluciones y sentencias que
          actualmente o en el futuro estén vigentes en Perú y resulten
          aplicables;
        </p>
        <p></p>
        <p>
          ● "Credenciales" significa Sus credenciales personales utilizadas para
          acceder al Sitio Web y/o Aplicación/App y operar su Cuenta;
        </p>
        <p></p>
        <p>
          ● "Central de Riesgo" significa aquella empresa cuya finalidad es
          proporcionar información sobre el historial crediticio de terceros;
        </p>
        <p></p>
        <p>
          ● "Historial Crediticio" significa un informe que muestra tu
          antecedente como receptor de préstamos, elaborado por una Central de
          Riesgo;
        </p>
        <p></p>
        <p>
          ● "Información confidencial" significa cualquier información y datos
          confidenciales o de propiedad de Y Credito o nuestros Afiliados,
          divulgados a El Usuario en relación con estos Términos y Condiciones,
          ya sea divulgados electrónicamente, oralmente o por escrito o mediante
          otros métodos disponibles para El Usuario.
        </p>
        <p></p>
        <p>
          ● "Préstamo" significa el monto principal del préstamo otorgado o que
          Y Credito le hará a El Usuario conforme a estos Términos y
          Condiciones;
        </p>
        <p></p>
        <p>
          ● "Dispositivo Móvil" incluye y significa su teléfono móvil, tarjeta
          SIM y/u otro equipo que, cuando se usan juntos, le permite acceder a
          la red y usar la Aplicación;
        </p>
        <p></p>
        <p>
          ● "Operador de Red Móvil" significa un operador de red móvil en Perú
          registrado por el Organismo Supervisor de Inversión Privada en
          Telecomunicaciones;
        </p>
        <p></p>
        <p>
          ● "Cuenta Bancaria" se refiere a aquel producto financiero, donde su
          principal función es ser lugar donde depositar el dinero físico para
          su movimiento y administración;
        </p>
        <p></p>
        <p>
          ● "Entidad Bancaria" se refiere a aquellas entidades que tienen en
          custodia el dinero que las personas y empresas depositan, otorgando
          préstamos, y usando esos recursos y para realizar inversiones, o entre
          otras actividades que la ley les autoriza;
        </p>
        <p></p>
        <p>
          ● "Tarjeta Bancaria" se refiere a aquel medio de pago emitido por una
          entidad financiera a favor del Usuario, a través de una cuenta
          asociada, y que es aceptada en infinidad de comercios y
          establecimientos;
        </p>
        <p></p>
        <p>
          ● "Información Personal" significa datos, ya sean verdaderos o no,
          sobre un individuo que puede identificarse a partir de esos datos, o
          de esos datos y otra información a la que tenemos o es probable que
          tengamos acceso. Esto puede incluir el nombre de un individuo,
          identificación nacional, fecha de nacimiento, sexo, antecedentes
          educativos, religión, fotografía, dirección física, dirección de
          correo electrónico, información de trabajo, estado civil, contacto de
          emergencia, número de teléfono, detalles de la tarjeta SIM,
          información financiera y de crédito, información (incluidos los
          detalles de la Cuenta bancaria), el ID de Cuenta y/o la contraseña que
          se utilizarán para acceder a la Aplicación después del registro y otra
          información que, cuando se toma junto con otra información, permitir
          que un individuo sea identificado;
        </p>
        <p></p>
        <p>
          ● "Solicitud" significa una solicitud o instrucción recibida por Y
          Credito del Usuario o supuestamente del Usuario a través del Sistema y
          sobre la cual estamos autorizados a actuar;
        </p>
        <p></p>
        <p>
          ● "Sistema" significa el sistema Y Credito provisto por Y Credito para
          proporcionar a los Usuarios el Servicio, incluido el Sitio Web y/o
          Aplicación/App y otros softwares y servicios de soporte relacionados;
        </p>
        <p></p>
        <p>
          ● "Territorio" significa el territorio que abarca la República del
          Perú;
        </p>
        <p></p>
        <p>
          ● "Las tarifas de transacción" incluyen las tarifas y cargos pagaderos
          por el uso del Servicio según lo publicado por Y Credito en la
          Aplicación o por cualquier otro medio que determinemos a Nuestro
          exclusivo criterio. Las tarifas de transacción están sujetas a cambios
          en cualquier momento a nuestro exclusivo criterio;
        </p>
        <p></p>
        <p>
          ● "Cookies": son los archivos de datos que se almacenan en el
          dispositivo del Usuario, cuando éstos tienen acceso al Sitio Web o la
          Aplicación/App.
        </p>
        <p></p>
        <p>3. RESPONSABILIDADES DEL USUARIO</p>
        <p></p>
        <p>El Usuario confirma y se compromete con Y Credito que:</p>
        <p></p>
        <p>
          3.1 Tiene pleno poder y autoridad para celebrar y estar legalmente
          obligado por estos Términos y Condiciones y cumplir con sus
          obligaciones en virtud de estos Términos y Condiciones;
        </p>
        <p></p>
        <p>
          3.2 Deberá cumplir en todo momento con todas las Leyes Vigentes y
          estos Términos y Condiciones, y nos notificará si incumple alguna de
          las Leyes Vigentes o estos Términos y Condiciones;
        </p>
        <p></p>
        <p>
          3.3 Solo usará el Sistema y el Servicio para fines legales y solo para
          los fines para los que está destinado a ser utilizado;
        </p>
        <p></p>
        <p>
          3.4 Deberá asegurarse de que todos los documentos, Información
          Personal y Credenciales que nos haya proporcionado (o en su nombre) a
          Y Credito o de otro modo a través del sistema sean precisos, actuales,
          completos y no engañosos;
        </p>
        <p></p>
        <p>3.5 Solo utilizará una Cuenta que esté autorizado a utilizar;</p>
        <p></p>
        <p>
          3.6 No deberá participar en ninguna conducta fraudulenta, engañosa o
          engañosa; y
        </p>
        <p></p>
        <p>
          3.7 No perjudicará ni eludirá el funcionamiento adecuado de la red en
          la que opera el Sistema.
        </p>
        <p></p>
        <p>4. ACEPTACIÓN DE ESTOS TÉRMINOS Y CONDICIONES</p>
        <p></p>
        <p>
          4.1. El Usuario declara haber leído detenidamente -y comprendido-
          todos los términos establecidos en estos Términos y Condiciones y
          enmendados periódicamente por Y Credito antes de descargar la
          Aplicación/App, o registrar una Cuenta con Y Credito, que regirá el
          uso y el funcionamiento de la Aplicación/App y Cuenta.
        </p>
        <p></p>
        <p>
          4.2. Después de descargar la Aplicación, se considerará que acepta
          estos Términos y Condiciones al hacer clic en la opción "Aceptar" en
          nuestro sistema y le pedirá que confirme que ha leído, entendido y
          aceptado cumplir con estos Términos y Condiciones.
        </p>
        <p></p>
        <p>
          4.3. Al descargar la Aplicación y registrar una Cuenta, El Usuario
          acepta cumplir y estar sujeto a estos Términos y Condiciones que rigen
          el funcionamiento de la Cuenta y afirma que los Términos y Condiciones
          del presente documento son sin perjuicio de cualquier otro derecho que
          podamos tener con respecto a la cuenta legal o de otro tipo.
        </p>
        <p></p>
        <p>
          4.4. Estos Términos y Condiciones pueden ser modificados por Y Credito
          de tiempo en tiempo y el uso continuado del Servicio constituye su
          aceptación de estar sujeto a los Términos y Condiciones de cualquier
          modificación o variación. Tomaremos todas las medidas razonables para
          notificarle cualquier cambio.
        </p>
        <p></p>
        <p>
          4.5. De tiempo en tiempo, se pueden emitir actualizaciones de la
          Aplicación y/o del Sitio web. Dependiendo de la actualización, es
          posible que no pueda usar el Servicio hasta que haya descargado o
          transmitido la última versión de la Aplicación y haya aceptado los
          nuevos términos y condiciones de estos Términos y Condiciones.
        </p>
        <p></p>
        <p>
          4.6. Al usar la Aplicación o cualquiera de los Servicios, El Usuario
          acepta que recopilemos y usemos información técnica sobre el
          Dispositivo Móvil y el software, hardware y periféricos relacionados
          para el Servicio que están basados en internet o inalámbricos para
          mejorar nuestros productos y proporcionar cualquier Servicio.
        </p>
        <p></p>
        <p>
          4.7. El Usuario autoriza expresamente a Y Credito, para que por
          conducto de nuestros funcionarios facultados llevemos a cabo
          investigaciones sobre su comportamiento crediticio en la Central de
          Riesgo que estimemos conveniente. Así mismo, declara que conoce la
          naturaleza y el alcance de la Información Personal que se solicitará,
          del uso que haremos de tal Información Personal y de que podremos
          realizar consultas periódicas de su Historial Crediticio, consintiendo
          que esta autorización se encuentre vigente por un período de 3 años
          contados a partir de la fecha de su expedición y en todo caso durante
          el tiempo que mantengamos relación jurídica.
        </p>
        <p></p>
        <p>
          4.8. También nos autoriza expresamente a contactar a El Usuario y a su
          contacto de emergencia que lo haya acordado expresamente, para
          verificar su Información Personal o cuando no podamos comunicarnos con
          El Usuario a través de otros métodos o cuando no hayamos recibido su
          pago en relación con el Préstamo establecido en Cláusula 11 de este
          documento.
        </p>
        <p></p>
        <p>
          4.9. El Usuario autoriza a Y Credito a usar esta Tarjeta Bancaria para
          la deducción automática.
        </p>
        <p></p>
        <p>5. SU USO DEL SERVICIO</p>
        <p></p>
        <p>
          5.1. El Servicio ofrecido por Y Credito sólo puede ser utilizado por
          personas mayores de 18 años.
        </p>
        <p></p>
        <p>
          5.2. Por la presente, reconoce y acepta que la aceptación por parte de
          Y Credito sobre Solicitud de una Cuenta no crea ninguna relación
          contractual entre El Usuario y las Entidades Bancarias más allá de los
          términos y condiciones que se aplican a su Cuenta Bancaria.
        </p>
        <p></p>
        <p>
          5.3. Nos reservamos el derecho de rechazar su Solicitud de Préstamo o
          de revocarla en cualquier momento a nuestra exclusiva y absoluta
          discreción y sin asignar ningún motivo ni notificarlo.
        </p>
        <p></p>
        <p>
          5.4. Nos reservamos el derecho (a nuestro exclusivo y absoluto
          criterio) de emitir, y/o rechazar un Préstamo y/o variar los términos
          de cualquier Préstamo dependiendo de nuestra evaluación del perfil de
          crédito del Usuario de tiempo en tiempo. Los términos del Préstamo y
          la tasa de interés pagadera en relación con cada Solicitud de Préstamo
          se mostrarán en la Aplicación.
        </p>
        <p></p>
        <p>6. SU USO DEL SISTEMA</p>
        <p></p>
        <p>Derechos otorgados y reservados</p>
        <p></p>
        <p>
          6.1. Sujeto a su cumplimiento de estos Términos y Condiciones, Y
          Credito y nuestros licenciantes (si corresponde) le otorgamos una
          licencia de acceso al Sistema revocable, limitada, no exclusiva, no
          transferible y libre de regalías durante el plazo de estos Términos y
          Condiciones y en el Territorio, para acceder y utilizar el Sistema
          únicamente para su uso personal con el fin de adquirir el Servicio
          proporcionado por Y Credito.
        </p>
        <p></p>
        <p>
          6.2. Todos los derechos que no se le otorgan expresamente en virtud de
          estos Términos y Condiciones están reservados por Y Credito y nuestros
          otorgantes de licencias (si corresponde). Nada en estos Términos y
          Condiciones transfiere a El Usuario ninguna propiedad del Sistema (en
          su totalidad o en parte).
        </p>
        <p></p>
        <p>Conductas prohibidas</p>
        <p></p>
        <p>6.3. Mientras usa el Sistema, no deberá:</p>
        <p></p>
        <p>
          6.3.1. licenciar, sublicenciar, vender, revender, transferir, asignar,
          distribuir o explotar comercialmente o poner a disposición de terceros
          de cualquier otra forma el Sistema;
        </p>
        <p></p>
        <p>
          6.3.2. modificar o realizar trabajos derivados basados en el Sistema,
          o realizar ingeniería inversa o acceder al software subyacente por
          cualquier motivo;
        </p>
        <p></p>
        <p>
          6.3.3. usar el Sistema para construir un producto o Servicio
          competitivo, construir un producto usando ideas, características,
          funciones o gráficos similares al Sistema, copiar ideas,
          características, funciones o gráficos del Sistema, o lanzar un
          programa o script automatizado que puede realizar múltiples
          solicitudes de servidor por segundo, o que carga o dificulta
          indebidamente la operación y/o el rendimiento del Sistema, o intenta
          obtener acceso no autorizado al Sistema o sistemas o redes
          relacionados;
        </p>
        <p></p>
        <p>
          6.3.4. usar cualquier aplicación o proceso para recuperar, indexar,
          "extraer datos", o de cualquier manera reproducir o eludir la
          estructura de navegación, presentación o contenido del Sistema;
        </p>
        <p></p>
        <p>
          6.3.5. publicar, distribuir o reproducir de cualquier manera cualquier
          material con derechos de autor, marcas comerciales u otra Información
          Confidencial de propiedad sin obtener el consentimiento previo del
          propietario de dichos derechos de propiedad, o eliminar cualquier
          aviso de derechos de autor, marca registrada u otros derechos de
          propiedad contenidos en el Sistema;
        </p>
        <p></p>
        <p>
          6.3.6. enviar o almacenar cualquier material con fines ilegales o
          fraudulentos;
        </p>
        <p></p>
        <p>
          6.3.7. enviar spam u otros mensajes no solicitados, o causar
          molestias, molestias, inconvenientes o hacer reservas falsas;
        </p>
        <p></p>
        <p>
          6.3.8. enviar o almacenar material infractor, obsceno, amenazante,
          difamatorio o ilegal o tortuoso;
        </p>
        <p></p>
        <p>
          6.3.9. enviar material que contenga virus de software, gusanos,
          troyanos u otro código informático, archivos, scripts, agentes o
          programas dañinos;
        </p>
        <p></p>
        <p>
          6.3.10. interferir o alterar la integridad o el rendimiento del
          Sistema o los datos contenidos en el mismo;
        </p>
        <p></p>
        <p>
          6.3.11. hacerse pasar por cualquier persona o entidad o tergiversar su
          afiliación con una persona o entidad;
        </p>
        <p></p>
        <p>6.3.12. tergiversar deliberadamente su ubicación;</p>
        <p></p>
        <p>
          6.3.13. tergiversar cualquier Información Personal importante sobre El
          Usuario, o que pueda relacionar nuestra decisión de participar en
          cualquier negocio relacionado u otro con El Usuario;
        </p>
        <p></p>
        <p>
          6.3.14. dañar nuestra reputación o la de cualquiera de las compañías
          de nuestro grupo de alguna manera;
        </p>
        <p></p>
        <p>
          6.3.15. recopilar o recolectar cualquier Información Confidencial o
          datos de cualquier servicio o nuestros sistemas o intentar descifrar
          cualquier transmisión hacia o desde los servidores que ejecutan
          cualquier Servicio.
        </p>
        <p></p>
        <p>7. SU CUENTA</p>
        <p></p>
        <p>
          7.1. Para acceder al Sistema como Usuario, debe registrarse y mantener
          una Cuenta como Usuario de la Aplicación.
        </p>
        <p></p>
        <p>
          7.2. El Usuario es responsable de todas las actividades realizadas en
          su Cuenta, El Usuario:
        </p>
        <p></p>
        <p>7.2.1. solo debe tener una Cuenta;</p>
        <p></p>
        <p>
          7.2.2. debe mantener la Información Personal de su Cuenta confidencial
          y segura;
        </p>
        <p></p>
        <p>
          7.2.3. no debe proporcionar a ninguna otra persona acceso a su Cuenta,
          incluso para transferir la Cuenta o la Información Personal de su
          Cuenta a otra persona;
        </p>
        <p></p>
        <p>
          7.2.4. debe notificarnos de inmediato si sospecha que tienen acceso o
          uso no autorizado a su Cuenta;
        </p>
        <p></p>
        <p>
          7.3. Nos reservamos el derecho de bloquear o denegar el acceso a su
          Cuenta, y/o bloquear las funciones disponibles en la Aplicación, sin
          perjuicio de nuestros otros derechos y recursos:
        </p>
        <p></p>
        <p>
          7.3.1. si consideramos, a nuestro exclusivo criterio, que El Usuario
          ha violado cualquier término de estos Términos y Condiciones;
        </p>
        <p></p>
        <p>7.3.2. durante una investigación;</p>
        <p></p>
        <p>
          7.3.3. si nos debe capital, intereses, tasas de transacción o
          impuestos a Y Credito o cualquiera de las Compañías de nuestro Grupo;
        </p>
        <p></p>
        <p>
          7.3.4. si estos Términos y Condiciones se rescinden por cualquier
          motivo; o
        </p>
        <p></p>
        <p>7.3.5. en cualquier otro momento a nuestra discreción razonable.</p>
        <p></p>
        <p>8. SU INFORMACIÓN PERSONAL</p>
        <p></p>
        <p>
          El Usuario acepta y consiente que usemos y procesemos su Información
          Personal tal como se establece en la Política de Privacidad
          https://www.y-credit.net/privacy-agreement, modificada periódicamente
          por Y Credito.
        </p>
        <p></p>
        <p>9. SOLICITUDES HECHAS POR EL USUARIO</p>
        <p></p>
        <p>
          9.1. Por la presente, nos autoriza irrevocablemente a actuar sobre
          todas las Solicitudes que recibimos del Usuario (o supuestamente del
          Usuario) a través del Sistema y a responsabilizarlo con respecto a las
          mismas.
        </p>
        <p></p>
        <p>
          9.2. Sujeto a nuestra discreción, nos reservamos el derecho de
          rechazar cualquier Solicitud en relación con una Solicitud de Préstamo
          del Usuario, incluso si previamente le hemos emitido un Préstamo.
        </p>
        <p></p>
        <p>
          9.3. Tendremos derecho a aceptar y actuar sobre cualquier Solicitud,
          incluso si esa Solicitud es de otro modo incompleta o ambigua si, a
          nuestra absoluta discreción, creemos que puede corregir la Información
          Personal incompleta o ambigua en la Solicitud sin ninguna referencia a
          ti siendo necesario.
        </p>
        <p></p>
        <p>
          9.4. Se considerará que hemos actuado correctamente y que hemos
          cumplido todas las obligaciones que se le adeudan a pesar de que la
          Solicitud puede haberse iniciado, enviado o comunicado de otra manera
          por error o de manera fraudulenta, y El Usuario estará obligado por
          cualquier Solicitud en la que podamos actuar si hemos actuado de buena
          fe creyendo que El Usuario ha enviado tales instrucciones.
        </p>
        <p></p>
        <p>
          9.5. Podemos, a nuestra absoluta discreción, negarnos a actuar de
          acuerdo con o en su totalidad o en parte de su Solicitud a la espera
          de una nueva consulta o confirmación adicional (ya sea por escrito o
          no) del Usuario.
        </p>
        <p></p>
        <p>
          9.6. El Usuario acepta y nos liberará e indemnizará de todos los
          reclamos, pérdidas, daños, costos y gastos que surjan como
          consecuencia de, o de alguna manera relacionados con Y Credito, haber
          actuado de acuerdo con la totalidad o parte de cualquiera de sus
          Solicitudes (o no ejerció) la discreción que le fue conferida.
        </p>
        <p></p>
        <p>
          9.7. El Usuario reconoce que, en la medida en que lo permita la Ley
          Aplicable, no seremos responsables de ningún sorteo, transferencia,
          envío, divulgación, actividad o incidente no autorizados en su Cuenta
          por el conocimiento y/o uso o manipulación de Su PIN de la Cuenta,
          contraseña, identificación o cualquier medio, ya sea ocasionado por su
          negligencia.
        </p>
        <p></p>
        <p>
          9.8. Estamos autorizados a ejecutar las órdenes con respecto a su
          Cuenta, según lo requiera cualquier orden judicial o autoridad o
          agencia competente según la Ley Aplicable.
        </p>
        <p></p>
        <p>
          9.9. En caso de conflicto entre los términos de cualquier Solicitud
          recibida por Y Credito del Usuario y estos Términos y Condiciones,
          prevalecerán estos Términos y Condiciones.
        </p>
        <p></p>
        <p>10. MEDIOS ELECTRÓNICOS DE AUTENTICACIÓN</p>
        <p></p>
        <p>
          El Usuario acepta y consiente que para los casos en los que sea
          necesario su consentimiento expreso, podrá hacerlo con su aceptación
          mediante un "aceptar" (accept por su significado en inglés) en la
          "casilla o recuadro de selección" (checkbox por su significado en
          inglés), a través de medios electrónicos, incluyendo la Aplicación, o
          por cualquier otro medio contemplado en legislación aplicable a las
          operaciones de este Contrato.
        </p>
        <p></p>
        <p>11. SUS RESPONSABILIDADES</p>
        <p></p>
        <p>
          11.1. El Usuario deberá, a su propio cargo, proporcionar y mantener en
          un orden de operación seguro y eficiente su Dispositivo Móvil
          necesario para acceder al sistema y al servicio.
        </p>
        <p></p>
        <p>
          11.2. El Usuario será responsable de garantizar el rendimiento
          adecuado de su Dispositivo Móvil. No seremos responsables de los
          errores o fallas causados por un mal funcionamiento de su Dispositivo
          Móvil, ni seremos responsables de ningún virus informático o problemas
          relacionados que puedan estar asociados con el uso del Sistema, el
          Servicio y el Dispositivo Móvil. El Usuario será responsable de los
          cargos debidos a cualquier Proveedor de Servicios que le proporcione
          conexión a la red y no seremos responsables de las pérdidas o demoras
          causadas por dicho Proveedor de Servicios.
        </p>
        <p></p>
        <p>
          11.3. Su acceso a la Aplicación será a través de su Dispositivo Móvil.
          Es su responsabilidad verificar y asegurarse de descargar la
          Aplicación correcta para su Dispositivo Móvil. No somos responsables
          si no tiene un dispositivo compatible o si no tiene la última versión
          de la Aplicación para su Dispositivo Móvil.
        </p>
        <p></p>
        <p>
          11.4. Si su Dispositivo Móvil se pierde, se lo roban, se rompe y/o ya
          no está en su poder, y esto expone la Información Personal de su
          Cuenta y sus Credenciales a otra persona o afecta nuestros derechos
          y/o recursos legales, debe notificarnos de inmediato y seguir los
          procedimientos notificados por Y Credito. No seremos responsables de
          la divulgación de la Información Personal de su Cuenta y sus
          Credenciales a ningún tercero y por la presente acepta indemnizarlos y
          eximirnos de cualquier pérdida resultante de la divulgación de la
          Información Personal de su Cuenta y sus Credenciales.
        </p>
        <p></p>
        <p>
          11.5. El Usuario es el único responsable de contar con un plan móvil y
          de Internet apropiado y de cualquier tarifa cobrada por su Operador de
          Red Móvil, como las tarifas de teléfono, SMS y datos de Internet. El
          Usuario reconoce que su uso del Sistema puede consumir grandes
          cantidades de datos.
        </p>
        <p></p>
        <p>12. PAGOS E IMPUESTOS</p>
        <p></p>
        <p>
          12.1. El Usuario acepta pagar todas las tarifas de transacción
          aplicables según lo establecido en la Aplicación de tiempo en tiempo.
        </p>
        <p></p>
        <p>
          12.2. Todas las tarifas de transacción son pagaderas inmediatamente y
          no son reembolsables.
        </p>
        <p></p>
        <p>
          12.3. Todos los pagos que debe realizar en relación con estos Términos
          y Condiciones y Préstamo se calculan sin tener en cuenta los impuestos
          que deba pagar. Si se paga algún impuesto en relación con el pago,
          debe pagarnos un monto adicional igual al pago multiplicado por la
          tasa impositiva apropiada. Debe hacerlo al mismo tiempo que realiza el
          pago o cuando realicemos dicha demanda, incluso después del final o la
          finalización de la relación.
        </p>
        <p></p>
        <p>
          12.4. El Usuario acepta que Y Credito puede, a su exclusivo criterio,
          compensar cualquier monto que le debamos con cualquier monto que nos
          deba en virtud de estos Términos y Condiciones.
        </p>
        <p></p>
        <p>
          12.5. El Usuario acepta que Y Credito puede, a su exclusivo criterio,
          deducir automáticamente cualquier monto que nos deba en virtud de
          estos Términos y Condiciones de su Cuenta Bancaria o Tarjeta Bancaria
          registrada.
        </p>
        <p></p>
        <p>
          12.6. El Usuario acepta que Y Credito puede, a su exclusivo criterio,
          cobrar cualquier monto que nos deba en virtud de estos Términos y
          Condiciones a través de las Entidades Bancarias.
        </p>
        <p></p>
        <p>
          12.7. Acepta y está consciente de que todos los reembolsos/pagos deben
          realizarse a la Cuenta designada de acuerdo con el proceso de pago
          instruido o requerido por la Aplicación Y Credito, de lo contrario, la
          Aplicación Y Credito no puede recibir el reembolso del Usuario y tiene
          derecho a solicitar al Usuario a pagar el saldo restante. Si El
          Usuario paga a una Cuenta no indicada por la Aplicación, El Usuario
          asumirá todas las responsabilidades por sí mismo y no tendrá nada que
          ver con la Aplicación.
        </p>
        <p></p>
        <p>13. PREDETERMINADO</p>
        <p></p>
        <p>13.1. Un evento de incumplimiento ocurre cuando El Usuario:</p>
        <p></p>
        <p>
          13.1.1. no paga ninguna suma o cuota (incluidos todos los intereses
          devengados, los honorarios de transacción y el impuesto) pagadero por
          un Préstamo otorgado en virtud de estos Términos y Condiciones por un
          período de quince (15) días acumulativos a menos que la falta de pago
          sea causada únicamente por un error administrativo o problema técnico;
          o se declaran en quiebra.
        </p>
        <p></p>
        <p>
          13.2. En cualquier momento después de que ocurra un evento de
          incumplimiento que continúe, podemos, sin perjuicio de cualquier otro
          derecho o recurso otorgado por la Ley Aplicable:
        </p>
        <p></p>
        <p>
          13.2.1. rescindir estos Términos y Condiciones de acuerdo con la
          Cláusula 14 del presente documento;
        </p>
        <p></p>
        <p>
          13.2.2. declarar que el Préstamo (incluidos todos los intereses
          devengados, Las tarifas de transacción, los impuestos y todos los
          demás montos pendientes según estos Términos y Condiciones) se vence y
          paga inmediatamente, por lo que se vencen y se pagan inmediatamente;
        </p>
        <p></p>
        <p>
          13.2.3. proporcionar información sobre el caso de incumplimiento a las
          oficinas de la Central de Riesgos; e
        </p>
        <p></p>
        <p>
          13.2.4. imponer cargos por pagos atrasados a la tasa que se muestra en
          la Aplicación en relación con su incumplimiento de pago.
        </p>
        <p></p>
        <p>14. PLAZO Y RESCISIÓN</p>
        <p></p>
        <p>
          14.1. Estos Términos y Condiciones continuarán hasta su finalización
          de acuerdo con sus términos.
        </p>
        <p></p>
        <p>
          14.2. Podemos rescindir estos Términos y Condiciones y/o suspender o
          rescindir su uso del Sistema, el Servicio y su Cuenta en su totalidad
          o en parte:
        </p>
        <p></p>
        <p>
          14.2.1. en cualquier momento por cualquier motivo mediante
          notificación a El Usuario;
        </p>
        <p></p>
        <p>
          14.2.2. inmediatamente, con o sin previo aviso, si El Usuario incumple
          cualquiera de los términos de estos Términos y Condiciones, sin
          perjuicio de nuestros otros derechos y recursos;
        </p>
        <p></p>
        <p>
          14.2.3. si su Cuenta o acuerdo con un Operador de Red Móvil o Entidad
          Bancaria se rescinde por cualquier motivo;
        </p>
        <p></p>
        <p>
          14.2.4. cuando dicha suspensión o terminación sea necesaria como
          consecuencia de problemas técnicos o por razones de seguridad; para
          facilitar la actualización o actualización de los contenidos o la
          funcionalidad del Servicio de tiempo en tiempo; donde su Cuenta se
          vuelve inactiva o inactiva;
        </p>
        <p></p>
        <p>
          14.2.5. si se nos exige o se nos solicita que cumplamos con una orden
          o instrucción o una recomendación del gobierno, tribunal, regulador u
          otra autoridad competente; o
        </p>
        <p></p>
        <p>
          14.2.6. si decidimos suspender o suspender la prestación del Servicio
          por razones comerciales o por cualquier otro motivo que podamos
          determinar a nuestra absoluta discreción;
        </p>
        <p></p>
        <p>
          14.3. Al término o vencimiento de estos Términos y Condiciones por
          cualquier motivo, El Usuario deberá:
        </p>
        <p></p>
        <p>
          14.3.1. puntualmente (y en cualquier caso dentro de los tres días),
          pague el principal, los intereses, los honorarios de transacción o los
          impuestos que se nos adeuden (que serán pagaderos inmediatamente y
          pagaderos al finalizar); y
        </p>
        <p></p>
        <p>
          14.3.2. eliminar inmediatamente y eliminar completamente la aplicación
          de su Dispositivo Móvil.
        </p>
        <p></p>
        <p>
          14.4. Sin embargo, la rescisión no afectará los derechos y
          responsabilidades acumulados de ninguna de las partes.
        </p>
        <p></p>
        <p>
          14.5. Las partes no tendrán más obligaciones o derechos en virtud de
          estos Términos y Condiciones después de la terminación de los Términos
          y Condiciones, sin perjuicio de las obligaciones o derechos que se
          hayan acumulado a cualquiera de las partes en el momento de su
          terminación, salvo las disposiciones de las Cláusulas 2, 3, 8, 11, 13,
          14, 15 y 16 de estos Términos y Condiciones y cualquier otra cláusula
          que expresamente o por su naturaleza tenga la intención de sobrevivir,
          continuará teniendo efecto después del final de estos Términos y
          Condiciones.
        </p>
        <p></p>
        <p>15. INDEMNIZACIONES Y EXCLUSIÓN DE RESPONSABILIDAD</p>
        <p></p>
        <p>Indemnizaciones</p>
        <p></p>
        <p>
          15.1. Deberá defender, indemnizar y eximir de responsabilidad a Y
          Credito, a nuestros licenciantes y a los Afiliados de cada parte y sus
          respectivos funcionarios, directores, miembros, empleados y agentes de
          y contra todos y cada uno de los reclamos, costos, daños, pérdidas,
          responsabilidades y gastos (incluidos los honorarios y costos de
          abogados) que surjan de o en relación con:
        </p>
        <p></p>
        <p>15.2.</p>
        <p>
          15.2.1. su incumplimiento de cualquier término de estos Términos y
          Condiciones o cualquier Ley aplicable; y
        </p>
        <p></p>
        <p>15.2.2. Su uso del Sistema y/o el Servicio, que incluye:</p>
        <p></p>
        <p>
          15.2.2.1. cualquier reclamo de terceros que surja de su uso del
          Sistema y/o el Servicio;
        </p>
        <p></p>
        <p>
          15.2.2.2. cualquier pérdida o daño que pueda derivarse de su uso, mal
          uso, abuso o posesión de cualquier software de terceros, incluidos,
          entre otros, cualquier sistema operativo, software de navegador o
          cualquier otro paquete o programa de software;
        </p>
        <p></p>
        <p>
          15.2.2.3. cualquier acceso no autorizado a su cuenta o cualquier
          violación de la seguridad o cualquier destrucción o acceso a sus datos
          o cualquier destrucción o robo o daño a su Dispositivo Móvil;
        </p>
        <p></p>
        <p>
          15.2.2.4. cualquier pérdida o daño ocasionado por el incumplimiento
          por su parte de estos Términos y Condiciones y/o al proporcionar
          Información Personal incorrecta;
        </p>
        <p></p>
        <p>
          15.2.2.5. cualquier pérdida o daño ocasionado por la falla o falta de
          disponibilidad de instalaciones o sistemas de terceros o la
          incapacidad de un tercero para procesar una transacción o cualquier
          pérdida en la que podamos incurrir como consecuencia de cualquier
          incumplimiento de estos Términos y Condiciones.
        </p>
        <p></p>
        <p>Exclusión de responsabilidad</p>
        <p></p>
        <p>
          15.3. No seremos responsables de ninguna pérdida sufrida por El
          Usuario en caso de que el Servicio sea interferido o no esté
          disponible debido a la falla de su Dispositivo Móvil, o cualquier otra
          circunstancia que no esté bajo nuestro control, incluyendo, entre
          otros, Fuerza mayor o error, interrupción, demora o falta de
          disponibilidad del Sistema, falla del equipo de acción terrorista o de
          cualquier enemigo, pérdida de potencia, condiciones climáticas o
          atmosféricas adversas y falla de cualquier sistema de
          telecomunicaciones público o privado.
        </p>
        <p></p>
        <p>
          15.4. El Usuario reconoce que la Aplicación no ha sido desarrollada
          para cumplir con sus requisitos individuales y que, por lo tanto, es
          su responsabilidad asegurarse de que las instalaciones y funciones de
          la Aplicación descritas cumplan con sus requisitos.
        </p>
        <p></p>
        <p>
          15.5. Sólo suministramos la Aplicación para uso privado. El Usuario
          acepta no utilizar la Aplicación para fines comerciales, comerciales o
          de reventa, y no tenemos ninguna responsabilidad ante El Usuario por
          cualquier pérdida de ganancias, pérdida de negocios, interrupción de
          negocios o pérdida de oportunidades comerciales.
        </p>
        <p></p>
        <p>
          15.6. No seremos responsables de ninguna pérdida o daño sufrido por El
          Usuario como resultado de o en relación con:
        </p>
        <p></p>
        <p>
          15.6.1. cualquier defecto o falla en la Aplicación o en cualquier
          Servicio que resulte de que El Usuario haya alterado o modificado la
          Aplicación;
        </p>
        <p></p>
        <p>
          15.6.2. cualquier defecto o falla en la Aplicación que resulte de que
          El Usuario haya utilizado la Aplicación en violación de estos Términos
          y Condiciones;
        </p>
        <p></p>
        <p>
          15.6.3. su incumplimiento de la Cláusula 6 del presente documento;
        </p>
        <p></p>
        <p>
          15.6.4. indisponibilidad de fondos suficientes en su Cuenta Bancaria;
        </p>
        <p></p>
        <p>
          15.6.5. falla, mal funcionamiento, interrupción o indisponibilidad del
          sistema, su Dispositivo Móvil, la red o un sistema de su Cuenta
          Bancaria;
        </p>
        <p></p>
        <p>
          15.6.6. cualquier uso fraudulento o ilegal del Servicio, el Sistema
          y/o su Dispositivo Móvil; o
        </p>
        <p></p>
        <p>
          15.6.7. su incumplimiento de estos Términos y Condiciones y cualquier
          documento o Información Confidencial proporcionada por Y Credito sobre
          el uso del Sistema y el Servicio.
        </p>
        <p></p>
        <p>
          15.7. Bajo ninguna circunstancia seremos responsables ante El Usuario
          por cualquier pérdida de ganancias o ahorros anticipados o por
          cualquier pérdida o daño indirecto o consecuente de cualquier tipo,
          sea cual sea su causa, que surja o esté relacionado con el Servicio,
          incluso cuando exista la posibilidad de tal pérdida o se nos notifica
          el daño.
        </p>
        <p></p>
        <p>16. SITIOS DE TERCEROS</p>
        <p></p>
        <p>
          16.1. Podemos establecer en el Sitio Web o la Aplicación enlaces y
          punteros a otros sitios web o aplicaciones móviles que son operados y
          mantenidos por terceros ("Sitios o Aplicaciones de Terceros"). Estos
          enlaces se proporcionan únicamente como indicadores de información
          sobre temas que pueden serle útiles. Sin embargo, el establecimiento
          de cualquier enlace a Sitios o Aplicaciones de terceros no es una
          recomendación o aprobación por nuestra parte de ningún producto,
          servicio, información, bienes, ideas u opiniones que puedan
          encontrarse en Sitios o Aplicaciones de terceros.
        </p>
        <p></p>
        <p>
          16.2. No realizamos ninguna garantía, expresa o implícita, con
          respecto al contenido de los sitios o Aplicaciones de Terceros,
          incluida la precisión, integridad, confiabilidad o idoneidad de los
          mismos para cualquier propósito en particular. No garantizamos que
          ningún Sitio o Aplicación de Terceros esté libre de cualquier reclamo
          de derechos de autor, marca registrada u otra infracción. No
          garantizamos que ningún Sitio o Aplicación de Terceros esté libre de
          virus u otra contaminación.
        </p>
        <p></p>
        <p>
          16.3. El Usuario comprende que los Sitios y Aplicaciones de Terceros
          pueden tener una política de privacidad diferente a la nuestra y que
          los sitios y aplicaciones de terceros pueden proporcionar menos
          seguridad que la nuestra. La decisión de acceder a un Sitio o
          Aplicación de Terceros, o comprar o utilizar cualquiera de los
          productos o servicios anunciados o proporcionados en un Sitio o
          Aplicación de terceros es suya, a su exclusivo criterio.
        </p>
        <p></p>
        <p>
          17. CONSENTIMIENTO PARA RECIBIR COMUNICACIONES DE MARKETING DIRECTO
        </p>
        <p></p>
        <p>
          Al utilizar los Servicios, El Usuario acepta recibir nuestras
          comunicaciones de marketing directo. Si no desea recibir nuestras
          comunicaciones de marketing, puede expresar su elección donde se
          indique en la comunicación correspondiente.
        </p>
        <p></p>
        <p>18. PROPIEDAD INTELECTUAL</p>
        <p></p>
        <p>
          El software asociado al Sitio Web y/o Aplicación/App y/o Sistema, así
          como todo el contenido, Información Confidencial y/o material
          disponible en el mismo, son propiedad Y Credito y/o de sus servidores
          externos; por tanto, están protegidos por las Leyes Vigentes y
          Tratados Internacional en materia de derechos de autor, marcas,
          patentes, modelos y diseños industriales aplicables en el Perú, sin
          importar que se encuentren o no registrados. Quedan reservados por Y
          Credito y/o de sus servidores externos; todos los derechos no
          otorgados expresamente en estos Términos y Condiciones.
        </p>
        <p></p>
        <p>
          Se encuentra terminantemente prohibido cualquier tipo de copia,
          distribución, transmisión, retransmisión, publicación, impresión,
          difusión y/o explotación comercial del material y/o contenido puesto a
          disposición a través de la Plataforma, sin el previo consentimiento
          expreso y por escrito de Y Credito. Cualquier violación a lo dispuesto
          en éste apartado constituirá una infracción a los derechos de
          propiedad intelectual y sujetará al infractor a las sanciones
          administrativas, civiles y será materia para la presentación de la
          querella, la denuncia o la reclamación ante las autoridades
          competentes, dentro de la cual se proporcionará la información
          necesaria para la investigación y persecución del ilícito del que se
          trate, obligándose el infractor a indemnizar y a liberar de toda
          responsabilidad a Y Credito por cualquier reclamación que se pudiere
          instaurar en su contra derivada de cualquier incumplimiento a lo
          dispuesto en este apartado.
        </p>
        <p></p>
        <p>19. COOKIES</p>
        <p></p>
        <p>
          Al ingresar al Sitio Web www.y-credit.net así como al hacer uso de
          nuestra Aplicación se utilizan elementos tecnológicos conocidos como
          Cookies. Las Cookies son pequeños archivos almacenados en su
          computadora o Dispositivo Móvil. Utilizamos Cookies para rastrear la
          actividad del Usuario para mejorar la interfaz y la experiencia del
          Usuario. La mayoría de los Dispositivos Móviles y navegadores de
          internet admiten el uso de Cookies; pero puede ajustar la
          configuración de su Dispositivo Móvil o navegador de Internet para
          rechazar varios tipos de ciertas Cookies o ciertas Cookies
          especificas. Su Dispositivo Móvil y/o navegador también le permitirán
          eliminar en cualquier momento las Cookies que se hayan almacenado
          previamente. Sin embargo, hacerlo puede afectar las funcionalidades
          disponibles en la Aplicación o en nuestro Sitio web.
        </p>
        <p></p>
        <p>20. VALIDEZ Y VIGENCIA DE LOS TÉRMINOS Y CONDICIONES</p>
        <p></p>
        <p>
          En caso que cualquier disposición del presente documento resulte
          inválida o nula por cualquier motivo, dicha disposición será separada
          y no afectará la validez y cumplimiento de las disposiciones
          restantes. Asimismo, la aplicación de dicha disposición a personas o
          situaciones que no sean aquéllas respecto de las cuales tal
          disposición es considerada inválida o nula, no se verán afectados y
          serán válidas y exigible en la mayor medida permitida por las Leyes
          Vigentes y en la medida que dicha interpretación sea consistente con
          la voluntad de las partes de conformidad con los términos y
          condiciones de la disposición como fuera originalmente redactada.
        </p>
        <p></p>
        <p>21. JURISDICCIÓN Y LEGISLACIÓN APLICABLE</p>
        <p></p>
        <p>
          Para todo lo relativo a la interpretación, cumplimiento y ejecución de
          las obligaciones derivadas de los presentes Términos y Condiciones,
          las Partes se someten a las Leyes Vigentes y a la jurisdicción de la
          República de Perú. Asimismo, para la resolución de cualquier
          controversia que se origine con motivo del mismo, las Partes se
          someten expresamente a la jurisdicción de los tribunales competentes
          en la ciudad de Lima, renunciando desde ahora a cualquier otra
          jurisdicción que pudiera corresponderles en razón de sus domicilios
          presentes o futuros o por la ubicación de sus bienes.
        </p>
        <p></p>
        <p>22. PROTECCIÓN DE DATOS PERSONALES Y PRIVACIDAD</p>
        <p></p>
        <p>
          De conformidad con la Ley de Protección de Datos Personales, aprobada
          por Ley No. 29733, Y Credito implementará todas las medidas posibles
          para mantener la confidencialidad y la seguridad de la Información
          Personal de los Usuarios, pero no responderá por los daños, pérdidas o
          perjuicios que pudieran derivarse de la violación de esas medidas por
          parte de terceros que utilicen las redes públicas o internet,
          alterando los sistemas de seguridad para obtener acceso a la
          Información Personal registrada en Y Credito.
        </p>
        <p></p>
        <p>23. NOTIFICACIONES Y AVISOS</p>
        <p></p>
        <p>
          Las notificaciones y/o avisos que se hagan las Partes se enviarán por
          correo electrónico a las direcciones que se establecen más abajo, ya
          sea para fines relacionados a presentar alguna queja, sugerencia o
          comentario en relación con el uso de la plataforma o a los presentes
          Términos y Condiciones, incluyendo sus posibles modificatorias. Todas
          las notificaciones, avisos o comunicaciones que las partes se dirijan
          en términos de esta cláusula se entenderán recibidas al día inmediato
          siguiente a la fecha de su entrega. Las partes señalan como sus
          direcciones de correo electrónico para efectos de lo anterior, las
          siguientes:
        </p>
        <p></p>
        <p>● Y Credito: hola@y-credit.net</p>
        <p></p>
        <p>● El Usuario: el registrado en el Sitio Web y/o Aplicación.</p>
        <p></p>
        <p>25. CONSENTIMIENTO</p>
        <p></p>
        <p>
          El Usuario en este acto, acepta que ha leído detenidamente y comprende
          el contenido y alcance de todas y cada una de las disposiciones
          contenidas en los presentes Términos y Condiciones, en la Política de
          Privacidad y demás documentos publicados por Y Credito.
        </p>
        <p></p>
        <p>Fecha de última actualización: 19 de marzo de 2024.</p>
      </div>
    );
  }
}
export default ServiceAgreement;
