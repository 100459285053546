import React from "react";
import PropTypes from "prop-types";

import { enquireScreen } from "enquire-js";
import logoBg from "../assets/imgs/logo.png";

class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
  };

  state = {};

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ menuMode: b ? "inline" : "horizontal" });
    });
  }

  render() {
    return (
      <header {...this.props}>
        <div
          className={[
            "headerWrapper",
            this.props.isMobile && "headerWrapper-mobile",
          ].join(" ")}
        >
          <img src={logoBg} className="logo" alt="logo" />
        </div>
      </header>
    );
  }
}

export default Header;
